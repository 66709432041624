/* eslint-disable guard-for-in */
import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  DomainConverter,
  Epic,
  EpicC,
  EpicI,
  Feature,
  FeatureC,
  FeatureI,
  LinkedFeature,
  RequirementI,
  Story,
  StoryI,
  StoryT,
  TaskI,
  TypeRequirement,
  UserStoryC,
  UserStoryI
} from '../requirements.model'
import { Solution } from '../../../solutions-store/store/solutions.model'

import { adapter, KanbanItemState } from './requirement.reducer'
import { Id } from '../../../arts-store/art.model'
import { FeaturesState, selectFeatures } from '../../../features.state'
import { Dictionary } from '@ngrx/entity'

export const selectItemsState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.items
)

/* export const selectKanbanItems = createSelector(
  selectItemsState,
  (state: ItemsState) => state.kanbanitems
)*/
/*
export const selectKanbanItemsState = createFeatureSelector<
  State8,
  KanbanItemState
>(itemsFeatureKey)
*/

/*
export const selectValueStreams = createSelector(
  selectValueStreamsState,
  (state: State) => state.valuestreams
)

export const selectValueStreamsState = createFeatureSelector<
  State6,
  ValueStreamState
>(stateFeatureKey)
*/

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectItemsState)

export const getkanbanItemById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)

export const selectlastCode = createSelector(
  selectItemsState,
  (state: KanbanItemState) => state.code
)
export const selectTotalelements = createSelector(
  selectItemsState,
  (state: KanbanItemState) => state.totalElements
)
export const selectTotalPages = createSelector(
  selectItemsState,
  (state: KanbanItemState) => state.totalPages
)
export const selectCurrentPage = createSelector(
  selectItemsState,
  (state: KanbanItemState) => state.currentPage
)
export const selectPage = createSelector(
  selectItemsState,
  (state: KanbanItemState) => state.page
)
export const selectPageSize = createSelector(
  selectItemsState,
  (state: KanbanItemState) => state.pageSize
)

export const getKanbanItemsByPage = createSelector(
  selectAll,
  (
    entities: RequirementI[],
    { size, page }: { size: string; page: string }
  ) => {
    // TODO
  }
)

export const getNumberofChildrenByParentId = (parentId: string) =>
  createSelector(
    selectAll,
    (entities) =>
      entities.filter((entity) => entity.parentId === parentId).length
  )

export const getkanbanItemByIdFactory = (id) =>
  createSelector(selectEntities, (entities) => entities[id])

export const getFeatureIdFactory = (id) =>
  createSelector(selectEntities, (entities) => entities[id] as Feature)

export const getStoryIdFactory = (id) =>
  createSelector(selectEntities, (entities) => entities[id] as Story)

export function createInstances(entity: RequirementI) {
  if (entity.name === 'userstory') {
    return DomainConverter.fromDto<UserStoryC>(UserStoryC, entity)
  } else if (entity.name === 'feature') {
    return DomainConverter.fromDto<FeatureC>(FeatureC, entity)
  } else if (entity.name === 'epic' || entity.name === undefined) {
    return DomainConverter.fromDto<EpicC>(EpicC, entity)
  }
}

export const getEpics = () =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter((item) => item.name === 'epic')
  )

export const getLastEpic = createSelector(getEpics(), (entities: Epic[]) => {
  if (entities) {
    const maxCreationDate = Math.max.apply(
      null,
      entities.map((u) => u.creationDate)
    )
    let kanbanitems = entities.filter((u) => u.creationDate === maxCreationDate)
    if (kanbanitems) {
      return kanbanitems[0]
    }

    const maxUpdateDate = Math.max.apply(
      null,
      entities.map((u) => u.lastUpdate)
    )
    kanbanitems = entities.filter((u) => u.lastUpdate === maxUpdateDate)
    if (kanbanitems) {
      return kanbanitems[0]
    }

    return entities[entities.length - 1]
  }

  return undefined
})

export const getEpicsByPortfolio = (portfolioId: string) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter(
      (item) => item.name === 'epic' && item.portfolioId === portfolioId
    )
  )

export const getEpicsById = (id: string) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter((item) => item.id === id)
  )

export const getEpicsByStrategicTheme = () =>
  createSelector(
    selectAll,

    (epics: RequirementI[], { strategicThemes }) => {
      const strategicTheme = strategicThemes.filter(
        (st) => st.status === 'ACTIVE'
      )
      // eslint-disable-next-line arrow-body-style
      const idEpicsInSt: any[] = strategicTheme.map((st) => st.epics[0]).sort()
      const epicsDoneAndImplementing = epics
        .filter((e) => e.state === 'Done' || e.state === 'Implementing')
        .sort()
      const epicsFound = epicsDoneAndImplementing?.filter((element) => {
        for (const key in idEpicsInSt) {
          if (element.id === idEpicsInSt[key].id) {
            return element
          }
        }
      })

      return epicsFound
    }
  )
export const getEpicsByPortfoliofactory = (portfolioId: String) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter(
      (item) => item.name === 'epic' && item.portfolioId === portfolioId
    )
  )
export const getEpicsByKanban = createSelector(
  selectAll,
  (entities: RequirementI[], { kanbanId }: { kanbanId }) =>
    entities.filter((item) => item.kanbanId === kanbanId)
)
export const getKanbanItemsByColumnId = createSelector(
  selectAll,
  (entities: RequirementI[], { columnId }: { columnId: string }) =>
    entities
      .filter((item) => item.columnId === columnId)
      .sort((a, b) => a.index - b.index)
)
export const getItemsByColumnId = (columnId: string) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities
      .filter((item) => item.columnId === columnId)
      .sort((a, b) => a.index - b.index)
  )

export const getItemsByColumnIdFilteredByParents = (
  columnId: string,
  parents: RequirementI[]
) =>
  createSelector(selectAll, (entities: RequirementI[]) => {
    if (parents.length > 0)
      return entities
        .filter(
          (item) =>
            item.columnId === columnId &&
            (item.parentId === undefined ||
              item.parentId === null ||
              parents.find((epic) => epic.id === item.parentId) !== undefined)
        )
        .map((item) => ({
          ...item,
          parentState: parents.find((p) => item.parentId === p.id)?.state
        }))
        .filter(Boolean)
        .sort((a, b) => a.index - b.index)
    else
      return entities.filter(
        (item) =>
          item.columnId === columnId &&
          (item.parentId === undefined ||
            item.parentId === null ||
            item.parentId === '')
      )
    //  entities
    //   .filter((item) => item.columnId === columnId)
    //   .sort((a, b) => a.index - b.index)
  })
/* export const getKanbanItemIdsyColumnId = createSelector(
  selectAll,
  (entities: Kanbanitem[], { columnId }: { columnId: string }) =>
    entities
      .filter((item) => item.columnId === columnId)
      .map((entity) => entity.id)
)
*/

export const getKanbanItemIdsyColumnId = (columnId: string) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities
      .filter((item) => item.columnId === columnId)
      .map((entity) => entity.id)
  )
export const getKanbanItemsByColumnIdAndProps = createSelector(
  selectAll,
  (
    entities: RequirementI[],
    {
      columnId,
      columnName,
      columnValue
    }: { columnId: string; columnName: string; columnValue: boolean }
  ) =>
    entities.filter(
      (item) => item.columnId === columnId && item[columnName] === columnValue
    )
)

export const selectFeaturesItem = () =>
  createSelector(
    selectAll,
    (entities) => entities.filter((f) => f.name === 'feature') as Feature[]
  )

export const selectorKanbanItems = createSelector(selectAll, (entities) =>
  entities.map((entity) => {
    // const cloned: RequirementI = { ...entity } // objectA destructured to create cloneOfObjectA

    const children = entities.filter((child) => child.parentId === entity.id)

    return {
      children,
      ...entity
    }
  })
)
/*
export const getChildKanbanItems = createSelector(
  selectAll,
  (entities: RequirementI[], children: ChildrenRequirement[]) => {
    const outItems: RequirementI[] = []
    entities.forEach((entity) => {
      const out = children.filter(
        (childrenItem) => childrenItem.parentId === entity.id
      )
      outItems.push({ ...entity, children: out })
    })
    return outItems
  }
)
*/
export const getKanbanItemsByProperties = (columnId, columnValue) =>
  createSelector(
    selectAll,
    (entities: RequirementI[]) =>
      entities.filter(
        (item) => item[columnId] === columnValue
        // && item['toKanban'] === false
      )
    // console.log('entity', entity);
  )
export const getKanbanItemsByPropertiesFactory = () =>
  createSelector(
    selectAll,
    (entities: RequirementI[], props) =>
      entities.filter(
        (item) => item[props.columnId] === props.columnValue
        // && item['toKanban'] === false
      )
    // console.log('entity', entity);
  )
export const getChildrenKanbanItems = (parentId) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities
      .filter((item) => item.parentId === parentId)
      .sort((itemA: RequirementI, itemB: RequirementI) => {
        // DELETE THE "-" AND TO NUMBER TO AVOID ERRORS LIKE : MVP-1, MVP-2, MVP-21, MVP-22, MVP-3, MVP-4..etc
        const a = Number(itemA.numeration?.split('-')[1])
        const b = Number(itemB.numeration?.split('-')[1])
        if (a >= b) {
          return 1
        } else if (a <= b) {
          return -1
        } else {
          return 0
        }
      })
  )

export const getKanbanItemsByProps = createSelector(
  selectAll,
  (entities: RequirementI[], props) =>
    entities.filter(
      (item) =>
        item[props.columnId] === props.columnValue && item.name === props.type
      // && item['toKanban'] === false
    )
  // console.log('entity', entity);
)
export const getKanbanItemsByPropsRoadMap = () =>
  createSelector(
    selectAll,
    (entities: RequirementI[], props) =>
      entities.filter(
        (item) =>
          item[props.columnId] === props.columnValue &&
          item.name === props.type &&
          (item.inRoadmap === undefined || item.inRoadmap === false)
      )
    // console.log('entity', entity);
  )

export const getKanbanItemByProps = createSelector(
  selectAll,
  (
    entities: RequirementI[],
    {
      columnId,
      columnValue,
      type
    }: { columnId: string; columnValue: any; type: string }
  ) =>
    entities.filter(
      (item) => item[columnId] === columnValue && item.name === type
    )
)
export const getKanbanItemByScopeNull = (
  portfolioId: any,
  type: string,
  columnIds: string[]
) =>
  createSelector(getEpics(), (entities: Epic[]) =>
    entities.filter(
      (item) =>
        // scope.indexOf({ id: item.valueStreamId }) > -1 ||
        (item.valueStreams === undefined || item.valueStreams === null) &&
        item.name === type &&
        item.portfolioId === portfolioId &&
        columnIds.indexOf(item.columnId) > -1
    )
  )
export const getKanbanItemByScope = (
  scope: Id[],
  portfolioId: any,
  type: string,
  columnIds: string[]
) =>
  createSelector(getEpics(), (entities: Epic[]) =>
    entities.filter((item) =>
      // scope.indexOf({ id: item.valueStreamId }) > -1 ||
      scope.some(
        (id) =>
          item.valueStreams?.filter((idVa) => idVa.id === id.id).length > 0 &&
          item.name === type &&
          item['portfolioId'] === portfolioId &&
          columnIds.indexOf(item.columnId) > -1
      )
    )
  )

export const getKanbanItemBySolutions = createSelector(
  selectorKanbanItems,
  (entities, { solutions }: { solutions: Solution[] }) => {
    const itemsOut: RequirementI[] = []
    solutions.forEach((solution) => {
      const entity = entities.filter((item) => item.solutionId === solution.id)
      itemsOut.push(...entity)
    })

    return itemsOut
  }
)
export const getKanbanItemBySolutionsFilterByName = createSelector(
  selectorKanbanItems,
  (entities, { solutions, name }: { solutions: Solution[]; name: string }) => {
    const itemsOut: TypeRequirement[] = []

    for (const solution of solutions) {
      const entity = entities.filter(
        (item) =>
          item.getSolutionId() === solution.id && item.getName() === name
      )
      // console.log('entity', entity);
      itemsOut.push(...entity)
    }
    return itemsOut
  }
)

export const getKanbanItemBySolutionId = createSelector(
  selectorKanbanItems,
  (entities, { solutionId }: { solutionId: string }) =>
    entities.filter((item) => item.getSolutionId() === solutionId)
)
export const getKanbanItemBySolutionIdAndName = createSelector(
  selectorKanbanItems,
  (entities, { solutionId, name }: { solutionId: string; name: string }) =>
    entities.filter(
      (item) => item.getSolutionId() === solutionId && item.getName() === name
    )
)
export const getKanbanItemBySolutionIdAndNameANdColumnId = createSelector(
  selectorKanbanItems,
  (
    entities,
    {
      solutionId,
      name,
      columnId
    }: { solutionId: string; name: string; columnId: string }
  ) =>
    entities.filter(
      (item) =>
        item.getSolutionId() === solutionId &&
        item.getName() === name &&
        item.getColumnId() === columnId
    )
)
export const selectorKanbanItemsEpicstoFeaturesBySolutions = createSelector(
  selectorKanbanItems,
  (entities, { solutions }: { solutions: Solution[] }) => {
    const itemsOut = []
    solutions.forEach((solution) => {
      const items = entities
        .filter(
          (item) =>
            item.getSolutionId() === solution.id && item.getName() === 'epic'
        )
        .map((item) => {
          const features = []
          features.push(
            ...entities.filter(
              (entity) =>
                entity.getParentId() === item.id && entity.name === 'feature'
            )
          )
          item.setChildren(features)

          return item
          // return { item, children: features } ;
        })
      itemsOut.push(...items)
    })
    return itemsOut
  }
)

export const getFeaturesByColumnIdinDone = (columnId: String) =>
  createSelector(selectorKanbanItems, (entities) =>
    entities
      .filter((f) => f.columnId === columnId)
      .filter((f) => f.state === 'Done')
  )
export const getItemsByKanbanIdWithColumnId = (kanbanId: String) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter((item) => item.kanbanId === kanbanId && item.columnId)
  )
export const getAllItemsByKanbanId = (kanbanId: String) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter((item) => item.kanbanId === kanbanId && item.columnId)
  )
export const getItemsByKanbanIdWithoutState = (kanbanId: String) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter(
      (item) =>
        item.kanbanId === kanbanId &&
        item.columnId === undefined &&
        item.parentId === undefined
    )
  )

/* export const getItemsByKeyResultIds = (keyResultIds: String[]) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter((item) => {
      for (const key in keyResultIds) {
        if (item.id === keyResultIds[key]) {
          return true
        }
      }
    })
  )*/
export const getItemsByKeyResultIds = (keyResultIds: string[]) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter((item) =>
      item.keyResultsId?.some((id) => keyResultIds.includes(id))
    )
  )

export const getItemsByKeyResultId = (keyResultId: string) =>
  createSelector(selectAll, (entities: RequirementI[]) =>
    entities.filter((item) => item.keyResultsId?.includes(keyResultId))
  )

export const getRelatedFeaturesByFeatureId = (id: string) =>
  createSelector(selectEntities, (entities: Dictionary<RequirementI>) => {
    const feature: Feature = entities[id] as Feature

    if (feature && feature.relations) {
      const linkedFeatures: LinkedFeature[] = []
      feature.relations.forEach((relation) => {
        const ent = entities[relation.relatedItemId] as Feature
        if (ent !== undefined) {
          const linkedList: LinkedFeature = {
            feature: { ...ent },
            relationId: relation.relationId,
            relationType: relation.relationType,
            ownerId: feature.id
          }
          linkedFeatures.push(linkedList)
        }
      })

      return linkedFeatures
    }
    return []
  })
