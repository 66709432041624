import {
  Injectable,
  Inject,
  OnInit,
  Injector,
  InjectionToken,
  TemplateRef,
  Type
} from '@angular/core'

import { Overlay, OverlayConfig, ScrollStrategy } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { OverlayFormRef } from './overlay-form-ref'
import { OverlayComponent } from './overlaycomponent/overlaycomponent.component'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

/** Injection token that can be used to access the data that was passed in to a dialog. */
export const INIT_DATA1 = new InjectionToken<any>('INIT_DATA1')

export const MOBILE_ANIMATION_FLAG = new InjectionToken<boolean>(
  'mobileAnimationFlag'
)

/** Valid ARIA roles for a dialog element. */
export type DialogRole = 'dialog' | 'alertdialog'

/** Possible overrides for a dialog's position. */
export interface DialogPosition {
  /** Override for the dialog's top position. */
  top?: string

  /** Override for the dialog's bottom position. */
  bottom?: string

  /** Override for the dialog's left position. */
  left?: string

  /** Override for the dialog's right position. */
  right?: string
}

export class FormOverlayConfig<D = any> {
  panelClass?: string
  hasBackdrop?: boolean
  backdropClass?: string

  /** Width of the dialog. */
  width?: number | string

  /** Height of the dialog. */
  height?: number | string

  /** Min-width of the dialog. If a number is provided, assumes pixel units. */
  minWidth?: number | string

  /** Min-height of the dialog. If a number is provided, assumes pixel units. */
  minHeight?: number | string

  /** Max-width of the dialog. If a number is provided, assumes pixel units. Defaults to 80vw. */
  maxWidth?: number | string = '80vw'

  /** Max-height of the dialog. If a number is provided, assumes pixel units. */
  maxHeight?: number | string

  /** Position overrides. */
  position?: DialogPosition

  /** Scroll strategy to be used for the dialog. */
  scrollStrategy?: ScrollStrategy
}

const DEFAULT_CONFIG: FormOverlayConfig = {
  hasBackdrop: true
  // backdropClass: 'dark-backdrop',
  // panelClass: 'tm-file-preview-dialog-panel'
}

export type PopoverContent = TemplateRef<any> | Type<any> | string
export interface PopoverParams<T> {
  origin: HTMLElement
  data?: T
  content: PopoverContent
}

@Injectable()
export class OverlayService {
  // Define the animation at the class level

  //  dialogRef: OverlayFormRef
  // Inject overlay service
  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private breakpointObserver: BreakpointObserver
  ) {}

  /* createInjector(popoverRef: OverlayFormRef): Injector {
    // const tokens = new WeakMap([[OverlayFormRef, popoverRef]]);
    // return new PortalInjector(this.injector, tokens);
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: OverlayFormRef, useValue: popoverRef }
      ]
    })
  }*/

  /* close() {
      this.dialogRef.close()
  }*/
  open<T>(
    config: FormOverlayConfig = {},
    { origin, data, content }: PopoverParams<T>
  ) {
    /* open<T>(
    config: EpicsFormOverlayConfig = {},
    initialData: any,
    kanbanId: string,
    columnId: string,
    epicId: string
  ) {*/

    const isMobile = this.breakpointObserver.isMatched(Breakpoints.Handset)
    // Override default configuration
    const dialogConfig = {
      ...DEFAULT_CONFIG,
      ...config,
      width: isMobile ? '98vw' : config.width, // Set width to 100% on mobile, otherwise use the provided config
      height: isMobile ? '98vh' : config.height // Set height to 100% on mobile, otherwise use the provided config
    }

    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this.createOverlay(dialogConfig)

    // Instantiate remote control
    const dialogRef = new OverlayFormRef<T>(overlayRef, content, data)

    // Create ComponentPortal that can be attached to a PortalHost
    // const filePreviewPortal = new ComponentPortal(SolutionsFormComponent);

    /* const filePreviewPortal = new ComponentPortal(
      EpicOverlayFormComponent,
      null,
      this.createInjector(
        Object.assign(initialData, {
          _service: this,
          _kanbanId: kanbanId,
          _columnId: columnId,
          _epicId: epicId
        })
      )
    );
    */
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: OverlayFormRef, useValue: dialogRef },
        { provide: INIT_DATA1, useValue: data },
        { provide: MOBILE_ANIMATION_FLAG, useValue: isMobile } // Pass the animation flag using the InjectionToken
      ]
    })
    // const injector = this.createInjector(dialogRef)
    const overlayFormPortal = new ComponentPortal(
      OverlayComponent,
      null,
      injector
    )

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(overlayFormPortal)

    dialogRef.updatePosition(config.position)

    overlayRef.backdropClick().subscribe((_) => dialogRef.close())

    // Return remote control
    return dialogRef
  }

  private createOverlay(config: FormOverlayConfig) {
    const overlayConfig = this.getOverlayConfig(config)
    return this.overlay.create(overlayConfig)
  }

  private getOverlayConfig(config: FormOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global()
    //     .centerVertically()
    //    .centerHorizontally()

    // const positionStrategy = this.overlay.position().flexibleConnectedTo(origin);
    /* , {
      originX: 'start',
      originY: 'bottom'
    }, {
      overlayX: 'start',
      overlayY: 'bottom'
    });
    */
    const overlayConfig = new OverlayConfig({
      height: config.height || window.innerHeight * 0.75,
      width: config.width || window.innerWidth * 0.75, // Default as before (75%)
      maxHeight: config.maxHeight,
      minHeight: config.minHeight,
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy:
        config.scrollStrategy || this.overlay.scrollStrategies.block(),
      positionStrategy
    })

    return overlayConfig
  }
}
