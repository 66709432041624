const packageJson = require('../../../../package.json')

export const environment = {
  appName: 'Kvasar Agile Management',
  auth0: {
    domain: 'dev-k97g0lsy.eu.auth0.com',
    clientId: 'cp2hivXUCzelTGKp4WQymFibf39GZs3O',
    cacheLocation: 'localstorage',
    audience: 'https://kvasar.herokuapp.com//api/v1/',
    redirectUri: window.location.origin + '/#/login/callback',
    useRefreshTokens: true
  },
  stripe: {
    pricingTableId: 'prctbl_1NbQ6fLXaxiFF2EJaw8nhPLT',
    publishableKey:
      'pk_test_51IzpJyLXaxiFF2EJgp2Op0zwxKwU0hmw0Gy6wra5eBN3VHRT4Jx9PW6YWHzBXYm5DmaCCqaqNtqjf9YvRRnRwjD200UfKK6RLD'
  },
  envName: 'INT',
  production: false,
  test: true,
  i18nPrefix: '',
  portfolioURL: 'https://kvasar.herokuapp.com/api/v1/portfolios/',
  kanbansURL: 'https://kvasar.herokuapp.com/api/v1/kanbans/',
  itemSURL: 'https://kvasar.herokuapp.com/api/v1/items/',
  solutionsURL: 'https://kvasar.herokuapp.com/api/v1/solutions/',
  teamsURL: 'https://kvasar.herokuapp.com/api/v1/teams/',
  kanbanItemsURL: 'https://kvasar.herokuapp.com/api/v1/items/',
  kanbanItemsPaginationURL: 'https://kvasar.herokuapp.com/api/v1/items/paging/',
  objectiveURL: 'https://kvasar.herokuapp.com/api/v1/objectives/',
  programIncrementURL: 'https://kvasar.herokuapp.com/api/v1/pis/',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ARTURL: 'https://kvasar.herokuapp.com/api/v1/arts/',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ValueStreamURL: 'https://kvasar.herokuapp.com/api/v1/vas/',
  roadMapURL: 'https://kvasar.herokuapp.com/api/v1/roadmaps/',
  eventURL: 'https://kvasar.herokuapp.com/api/v1/events/',
  loginURL: 'https://kvasar.herokuapp.com/api/v1/login',
  logoutURL: 'https://kvasar.herokuapp.com/api/v1/logout',
  refreshTokenURL: 'https://kvasar.herokuapp.com/api/v1/refresh_token',
  strategicthemeURL: 'https://kvasar.herokuapp.com/api/v1/strategicthemes/',
  signup: 'https://kvasar.herokuapp.com/api/v1/register',
  organizationsURL: 'https://kvasar.herokuapp.com/api/v1/organizations/',
  organizationMembersURL: 'https://kvasar.herokuapp.com/api/v1/members/',
  usersURL: 'https://kvasar.herokuapp.com/api/v1/users/',
  historyaudit: 'https://kvasar.herokuapp.com/api/v1/history/',
  attachment: 'https://kvasar.herokuapp.com/api/v1/attachment/',
  openai: 'https://kvasar.herokuapp.com/api/v1/openai/',
  management: 'https://kvasar.herokuapp.com/api/v1/management/',
  kpi: 'https://kvasar.herokuapp.com/api/v1/kpis/',
  kpiinstance: 'https://kvasar.herokuapp.com/api/v1/kpi-instance/',
  changePasswordUrl: 'https://kvasar.herokuapp.com/api/v1/change_password',
  changeNameUrl: 'https://kvasar.herokuapp.com/api/v1/change_name',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome:
      packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress']
  }
}
