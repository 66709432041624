import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Update } from '@ngrx/entity'
import { select, Store } from '@ngrx/store'
import { forkJoin, from, interval, Observable, of } from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  mergeAll,
  mergeMap,
  switchMap,
  take,
  tap,
  toArray
} from 'rxjs/operators'
import { environment as env } from '../../../../environments/environment'
import { KanbanI } from '../../../kanban/store/kanban.model'
import {
  UserStoryI,
  TaskI,
  UserStoryC,
  FeatureI,
  StoryI,
  FeatureC,
  EpicI,
  StoryT,
  EpicC,
  EnablerStoryC,
  EnablerStoryI,
  isFeatureI,
  RequirementI,
  isEpicI,
  RequirementClass,
  isUserStoryI,
  Epic,
  StoryClass,
  isEnablerStoryI,
  UserStory,
  EnablerStory,
  Feature
} from '../../requirements-store/store/requirements.model'

import { Page, PageRequest } from '../../../shared/paginated/page'
import * as fromTeamSelectors from '../../teams-store/store/team.selectors'
import * as fromARTSelectors from '../../arts-store/art.selectors'
import * as fromkanbanitemsSelectors from '../../requirements-store/store/item/requirement.selectors'
import { HistoryAudit } from '../../history-audit-store/history-audit.model'
import { eventNames } from 'process'

export type Operation = 'add' | 'remove' | 'replace' | 'copy' | 'move' | 'test'
export interface Patch {
  op: Operation
  path?: string
  value: any
}
export interface ItemQuery {
  search?: any
  registration?: Date
  param?: string
  parent?: string
}

export interface PatchMany {
  entityIds: string[]
  jsonPatches: Patch[][]
}

@Injectable({
  providedIn: 'root'
})
export class KanbanItemService {
  store: Store
  apiurl = env.kanbanItemsURL
  attachmentURL = env.attachment
  kanbanItemsPaginationURL = env.kanbanItemsPaginationURL

  lastEvent

  httpOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Access-Control-Allow-Origin': '*'
    })
  }
  httpPatchOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Access-Control-Allow-Origin': '*',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json-patch+json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/json-patch+json'
    })
  }

  constructor(public http: HttpClient, store: Store) {
    this.store = store
  }

  getAllKanbanItemsList() {
    const url = `${this.apiurl}`

    return this.http.get(this.apiurl, this.httpOptions).pipe(take(1))
  }
  getListChildren(parentId: string) {
    const childrenUrl = `${this.apiurl}${parentId}/children`

    return this.http
      .get<RequirementI[]>(childrenUrl, this.httpOptions)
      .pipe(take(1))
  }

  pagingKanbanItems(
    query: ItemQuery,
    pageRequest: PageRequest<RequirementI>
  ): Observable<Page<RequirementI>> {
    /* let params = new HttpParams()
    if (pageRequest.page) params.set('page', String(pageRequest.page))
    if (pageRequest.size) params.set('size', String(pageRequest.size))

    if (pageRequest.sort && pageRequest.sort.property)
      params.set('sort', String(pageRequest.sort.property))
    if (pageRequest.sort && pageRequest.sort.order)
      params.set('direction', String(pageRequest.sort.order))

    const requestOptions = {
      headers: this.httpOptions.headers,
      params: params
    }*/

    const sort =
      String(pageRequest?.sort?.property) !== undefined
        ? String(pageRequest?.sort?.property)
        : ''
    const direction =
      String(pageRequest?.sort?.order) !== undefined
        ? String(pageRequest?.sort?.order)
        : ''
    /*
        COMPACT WAY
        let searchParams = new HttpParams({
          fromObject: {
              query: query,
              sort: sort,
              order: order
          }
      });
      */
    let paramsSet = new HttpParams()
      .set('page', String(pageRequest?.page))
      .set('size', String(pageRequest?.size))
      .set('sort', `${sort},${direction}`)
    if (query.parent !== undefined && query.parent !== null)
      paramsSet = paramsSet.set('parent', query.parent)

    if (query.search)
      query.search.forEach((item: { key: string; value: string }) => {
        if (item.value) {
          paramsSet = paramsSet.set(item.key, item.value)
        }
      })

    return this.http
      .get<Page<RequirementI>>(this.kanbanItemsPaginationURL, {
        ...this.httpOptions,

        params: paramsSet
      })
      .pipe(take(1))

    /* return this.http
      .get<Page<Kanbanitem>>(this.kanbanItemsPaginationURL, {
        ...this.httpOptions,

        params: {
          page: String(pageRequest?.page),
          size: String(pageRequest?.size),
          sort: `${sort},${direction}`,
        }
      })
      .pipe(take(1))
      */
  }

  addKanbanItem(kanbanItem: RequirementI): Observable<RequirementI> {
    const url = `${this.apiurl}`

    return this.http
      .post<RequirementI>(url, kanbanItem, this.httpOptions)
      .pipe(take(1))
  }
  updateKanbanItem(kanbanItem: Update<RequirementI>): Observable<RequirementI> {
    const id = kanbanItem.id
    const url = `${this.apiurl}${id}`
    return this.http
      .put<RequirementI>(
        url,
        { ...kanbanItem.changes, id: kanbanItem.id },
        this.httpOptions
      )
      .pipe(take(1))
  }
  upsertKanbanItem(kanbanItem: RequirementI): Observable<RequirementI> {
    const id = kanbanItem.id
    const url = `${this.apiurl}${id}`
    return this.http
      .put<RequirementI>(url, kanbanItem, this.httpOptions)
      .pipe(take(1))
  }
  getKanbanItem(id: string): Observable<RequirementI> {
    const getItemURL = `${this.apiurl}${id}`
    return this.http
      .get<RequirementI>(getItemURL, this.httpOptions)
      .pipe(take(1))
  }

  getKanbanItemsByColumnId(columnId: string): Observable<RequirementI[]> {
    const getItemURL = `${this.apiurl}columnId/${columnId}`
    return this.http
      .get<RequirementI[]>(getItemURL, this.httpOptions)
      .pipe(take(1))
  }
  getAllEpicsByuser() {
    const getItemURL = `${this.apiurl}epics/`
    return this.http
      .get<RequirementI[]>(getItemURL, this.httpOptions)
      .pipe(take(1))
  }
  getAllKanbanItemsById(ids: string[]): Observable<RequirementI[]> {
    const getItemURL = `${this.apiurl}ids/`
    return this.http
      .post<RequirementI[]>(getItemURL, ids, this.httpOptions)
      .pipe(take(1))
  }
  getAllKanbanItemsByKeyResults(ids: string[]): Observable<RequirementI[]> {
    const getItemURL = `${this.apiurl}keyResults`
    return this.http
      .post<RequirementI[]>(getItemURL, ids, this.httpOptions)
      .pipe(take(1))
  }

  // TODO SET ANY UNTIL BETTER SOLUTION
  patchKanbanItem(
    kanbanItem: Update<RequirementI>,
    op?: Operation
  ): Observable<RequirementI> {
    const patchURL = `${this.apiurl}${kanbanItem.id}`
    const send: Patch[] = []

    const epicUpdate = kanbanItem as Update<Epic>
    const featureUpdate = kanbanItem as Update<Feature>
    const storyUpdate = kanbanItem as Update<EnablerStory | UserStory>

    if (epicUpdate.changes.economicalData !== undefined) {
      const ed: Patch = {
        op: 'add',
        path: '/economicalData',
        value: epicUpdate.changes.economicalData
      }
      send.push(ed)
    }
    if (epicUpdate.changes.expectedBenefit !== undefined) {
      const expectedBenefit: Patch = {
        op: 'add',
        path: '/expectedBenefit',
        value: epicUpdate.changes.expectedBenefit
      }
      send.push(expectedBenefit)
    }
    if (epicUpdate.changes.files !== undefined) {
      const files: Patch = {
        op: 'add',
        path: '/files',
        value: epicUpdate.changes.files
      }
      send.push(files)
    }
    if (epicUpdate.changes.dueDate !== undefined) {
      const dueDate: Patch = {
        op: 'add',
        path: '/dueDate',
        value: epicUpdate.changes.dueDate
      }
      send.push(dueDate)
    }
    if (epicUpdate.changes.startDate !== undefined) {
      const startDate: Patch = {
        op: 'add',
        path: '/startDate',
        value: epicUpdate.changes.startDate
      }
      send.push(startDate)
    }

    if (kanbanItem.changes.itemName !== undefined) {
      const name: Patch = {
        op: 'add',
        path: '/itemName',
        value: kanbanItem.changes.itemName
      }
      send.push(name)
    }
    if (kanbanItem.changes.description !== undefined) {
      const desc: Patch = {
        op: 'add',
        path: '/description',
        value: kanbanItem.changes.description
      }
      send.push(desc)
    }
    if (epicUpdate.changes.epicOwnerId !== undefined) {
      const owner: Patch = {
        op: 'add',
        path: '/epicOwnerId',
        value: epicUpdate.changes.epicOwnerId
      }
      send.push(owner)
    }
    if (featureUpdate.changes.featureOwnerId !== undefined) {
      const ownerFeature: Patch = {
        op: 'add',
        path: '/featureOwnerId',
        value: featureUpdate.changes.featureOwnerId
      }
      send.push(ownerFeature)
    }
    if (epicUpdate.changes.valueStreams !== undefined) {
      const vs: Patch = {
        op: 'add',
        path: '/valueStreams',
        value: epicUpdate.changes.valueStreams
      }
      send.push(vs)
    }
    if (featureUpdate.changes.relations !== undefined) {
      const relatedFeatures: Patch = {
        op: 'replace',
        path: '/relations',
        value: featureUpdate.changes.relations
      }

      send.push(relatedFeatures)
    }

    if (kanbanItem.changes.parentId !== undefined) {
      const parent: Patch = {
        op: 'add',
        path: '/parentId',
        value: kanbanItem.changes.parentId
      }

      send.push(parent)
    }

    if (epicUpdate.changes.forecastedEpicCost !== undefined) {
      const fe: Patch = {
        op: 'replace',
        path: '/forecastedEpicCost',
        value: epicUpdate.changes.forecastedEpicCost
      }
      send.push(fe)
    }

    if (epicUpdate.changes.keyResults !== undefined) {
      const kr: Patch = {
        op: 'add',
        path: '/keyResults',
        value: epicUpdate.changes.keyResults
      }
      send.push(kr)
    }

    if (epicUpdate.changes.keyResultsId !== undefined) {
      const krIds: Patch = {
        op: 'add',
        path: '/keyResultsId',
        value: epicUpdate.changes.keyResultsId
      }
      send.push(krIds)
    }

    if (kanbanItem.changes.columnId !== undefined) {
      const p: Patch = {
        op: 'add',
        path: '/columnId',
        value: kanbanItem.changes.columnId
      }
      send.push(p)
    }

    if (storyUpdate.changes.teamId !== undefined) {
      const team: Patch = {
        op: 'add',
        path: '/teamId',
        value: storyUpdate.changes.teamId
      }
      send.push(team)
    }

    if (kanbanItem.changes.index !== undefined) {
      const p: Patch = {
        op: 'replace',
        path: '/index',
        value: kanbanItem.changes.index
      }
      send.push(p)
    }
    if (kanbanItem.changes.toKanban !== undefined) {
      const i: Patch = {
        op: 'replace',
        path: '/toKanban',
        value: kanbanItem.changes.toKanban
      }
      send.push(i)
    }
    if (kanbanItem.changes.kanbanId !== undefined) {
      const i: Patch = {
        op: 'add',
        path: '/kanbanId',
        value: kanbanItem.changes.kanbanId
      }
      send.push(i)
    }
    if (kanbanItem.changes.inRoadmap !== undefined) {
      const k: Patch = {
        op: 'replace',
        path: '/inRoadmap',
        value: kanbanItem.changes.inRoadmap
      }
      send.push(k)
    }
    if (featureUpdate.changes.teams !== undefined) {
      const teams: Patch = {
        op: op ? op : 'add',
        path: '/teams',
        value: featureUpdate.changes.teams
      }
      send.push(teams)
    }
    if (featureUpdate.changes.programIncrementId !== undefined) {
      const programIncrement: Patch = {
        op: 'replace',
        path: '/programIncrementId',
        value: featureUpdate.changes.programIncrementId
      }
      send.push(programIncrement)
    }
    if (featureUpdate.changes.isMvp !== undefined) {
      const mvp: Patch = {
        op: op ? op : 'add',
        path: '/isMvp',
        value: featureUpdate.changes.isMvp
      }
      send.push(mvp)
    }
    if (featureUpdate.changes.solutionId !== undefined) {
      const mvp: Patch = {
        op: op ? op : 'add',
        path: '/solutionId',
        value: featureUpdate.changes.solutionId
      }
      send.push(mvp)
    }

    if (featureUpdate.changes.dor !== undefined) {
      const mvp: Patch = {
        op: 'replace',
        path: '/dor',
        value: featureUpdate.changes.dor
      }
      send.push(mvp)
    }

    if (
      kanbanItem.changes['assigned'] !== undefined &&
      kanbanItem.changes['assigned'] !== null
    ) {
      // console.log(kanbanItem.changes['assigned'])
      const j: Patch = {
        op: 'replace',
        path: '/assigned',
        value: kanbanItem.changes['assigned']
      }
      send.push(j)
    }

    return this.http
      .patch<RequirementI>(patchURL, send, this.httpPatchOptions)
      .pipe(take(1))
  }

  addRelatedFeature(id, relatedFeature) {
    const url = `${this.apiurl}${id}/relations`

    return this.http
      .put<RequirementI>(url, relatedFeature, this.httpOptions)
      .pipe(take(1))
  }
  removeRelatedItem(id, relatedFeatureId: string) {
    const url = `${this.apiurl}${id}/relations/${relatedFeatureId}`

    return this.http.delete<RequirementI>(url, this.httpOptions).pipe(take(1))
  }

  fetchItemsByProgramIncrementId(ids: string[]): Observable<any> {
    return from(ids).pipe(
      mergeMap((id) =>
        this.http
          .get<RequirementI[]>(`${this.apiurl}pi/${id}`, this.httpOptions)
          .pipe(
            take(1),
            catchError((error) => {
              console.error('Error in HTTP request:', error)
              return of([]) // Return an empty array in case of an error
            }),
            filter((data) => data && data.length > 0)
          )
      ),
      toArray(),
      mergeAll()
    )
  }

  /*
  patchEpic(kanbanItem: Update<Epic>) {
    const patchURL = `${this.apiurl}${kanbanItem.id}`
    const send: Patch[] = []

    if (kanbanItem.changes.economicalData !== undefined) {
      const ed: Patch = {
        op: 'replace',
        path: '/economicalData',
        value: kanbanItem.changes.economicalData
      }
      send.push(ed)
    }

    if (kanbanItem.changes.forecastedEpicCost !== undefined) {
      const fe: Patch = {
        op: 'replace',
        path: '/forecastedEpicCost',
        value: kanbanItem.changes.forecastedEpicCost
      }
      send.push(fe)
    }

    return this.http
      .patch<RequirementI>(patchURL, send, this.httpPatchOptions)
      .pipe(take(1))
  }
*/
  patchMultipleItems(query: PatchMany): Observable<number> {
    const patchURL = `${this.apiurl}patchMany`

    return this.http
      .patch<number>(patchURL, query, this.httpPatchOptions)
      .pipe(take(1))
  }
  deleteKanbanItem(id: string): Observable<RequirementI> {
    const deleteURL = `${this.apiurl}${id}`
    return this.http
      .delete<RequirementI>(deleteURL, this.httpOptions)
      .pipe(take(1))
  }

  getKanbanItems(data) {
    if (data === undefined || data === null) return null

    const kanbans: RequirementI[] = data as RequirementI[]

    return kanbans
  }

  pagedKanbanItems(data: Page<RequirementI>) {
    if (data === undefined || data === null) return null
    const kanbans: Page<RequirementI> = data as Page<RequirementI>
    return kanbans
  }
  /*
  getPagedKanbanItems(data) {
    if (data === undefined || data === null) return null

    const kanbans: Kanbanitem[] = data.content as Kanbanitem[]

    return kanbans
  }
  */

  convert(item: RequirementI): RequirementClass {
    let itemC
    if (isUserStoryI(item)) {
      itemC = new UserStoryC(item)
    } else if (isFeatureI(item)) {
      itemC = new FeatureC(item)
    } else if (isEpicI(item)) {
      itemC = new EpicC(item)
    } else if (isEnablerStoryI(item)) {
      itemC = new EnablerStoryC(item)
    } else {
      console.log('here')
    }

    return itemC
  }

  askForCode(name: string): Observable<string> {
    const codeULR = `${this.apiurl}recommendation_code`

    return this.http
      .get<string>(codeULR, { ...this.httpOptions, params: { source: name } })
      .pipe(take(1))
  }

  setLastEvent(event) {
    this.lastEvent = event
  }

  getLastEvent() {
    return this.lastEvent
  }

  getAssignedFeaturesByParent(parentId: string) {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(fromkanbanitemsSelectors.getkanbanItemByIdFactory(parentId)),
      filter((val) => val !== undefined),
      switchMap((val) =>
        this.store.select(fromkanbanitemsSelectors.getKanbanItemsByProps, {
          columnId: 'kanbanId',
          columnValue: val.kanbanId,
          type: 'feature'
        })
      )
    )
  }

  getTeamsAssignedByFeatureId(parentId: string) {
    const art$ = this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(fromkanbanitemsSelectors.getkanbanItemByIdFactory(parentId)),
      filter((val) => val !== undefined && val.kanbanId !== undefined),
      mergeMap((val) =>
        this.store.select(fromARTSelectors.getARTByKanbanId(val.kanbanId))
      )
    )

    const teams$ = art$.pipe(
      mergeMap((art) =>
        this.store.select(fromTeamSelectors.getTeamsByART(art.id))
      )
    )
    return teams$
  }

  getTeamsFromSameART(teamId: string) {
    return this.store.pipe(
      select(fromTeamSelectors.getTeamByIdF(teamId)),
      filter((val) => !!val),
      mergeMap((team) =>
        this.store.select(fromTeamSelectors.getTeamsByART(team.ARTId))
      )
    )
  }
  fetchItemsByColumnId(ids: string[]): Observable<any> {
    return from(ids).pipe(
      mergeMap((id) =>
        this.getKanbanItemsByColumnId(id).pipe(
          take(1),
          catchError((error) => {
            console.error('Error in HTTP request:', error)
            return of([]) // Return an empty array in case of an error
          }),
          filter((data) => data && data.length > 0)
        )
      ),
      toArray(),
      mergeAll()
      // concatMap((dataArray) => forkJoin(dataArray))
    )
  }
}
