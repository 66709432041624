<ngx-loading-bar color="#CD67BA" height="5px" [includeSpinner]="false" ref="router"></ngx-loading-bar>
<ngx-loading-bar color="#CD67BA" height="5px" ref="http" [includeSpinner]="true"></ngx-loading-bar>
<div [class]="'theme-wrapper ' + (theme$ | async)">
  <header>
    <div class="toolbar" [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'" [class.mat-elevation-z4]="(stickyHeader$ | async)">
      <mat-toolbar color="primary">
        <button
          *ngIf="isInitial$ |async as isInitial"
          class="hamburger" mat-icon-button
          (click)="toggleSidenav()" (mouseleave)="closeSidenav();">
          <mat-icon>menu</mat-icon>
        </button>

        <div class="branding-mobile  d-inline d-sm-none mobile-branding">
          <span routerLink="" class="branding spacer center">
            KVASAR
           </span>
        </div>

        <div>
          <div class="branding-mobile">
            <span routerLink="" class="branding spacer center d-none d-sm-inline d-md-none">
              <img [src]="logo" />
              {{'anms.title.short' | translate }}
            </span>
          </div>

          <div routerLink="" class="branding spacer d-none d-md-flex">
            <img [src]="logo" />
            <div class="title">
              <p class="title-main">KVASAR</p>
              <p class="title-label">{{ 'anms.title.long' | translate }}</p>
            </div>
          </div>
        </div>

        <div>
          <ng-container *ngIf="(isAuthenticated$ | async) as authenticated; else displayLanguage">
            <anms-menu ></anms-menu>
            <button
            mat-icon-button
            (click)="openAccountMenu()">
            <fa-icon icon="user-circle"></fa-icon>
          </button>
          </ng-container>



          <ng-template #displayLanguage>
          <span *ngIf="language$ | async as language" class="lang-select pr-3">
            <span class="material-icons pr-1 pt-2">
              language
              </span>
            <mat-select [ngModel]="language" (selectionChange)="onLanguageSelect($event)">
              <mat-option *ngFor="let l of languages" [value]="l">
                {{ l.toUpperCase() }}
              </mat-option>
            </mat-select>
          </span>
          </ng-template>
        </div>
        <!--
        <button mat-button mat-stroked-button color="accent" *ngIf="!(isAuthenticated$ | async)" (click)="onLoginClick()">
          {{ 'anms.menu.login' | translate }}
        </button>
        -->
      </mat-toolbar>
    </div>
  </header>
  <main cdkScrollable>
    <div class="content" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">
      <router-outlet #o="outlet"></router-outlet>
    </div>
    <div class="footer" style="display: none;">
      <!--div class="row">
        <div class="col-sm-12 links"-->
          <!--
          <a href="https://www.github.com/tomastrajan" target="_blank">
            <fa-icon [icon]="['fab','github']"></fa-icon>
            <span>Github</span>
          </a>
          <a href="https://www.medium.com/@tomastrajan" target="_blank">
            <fa-icon [icon]="['fab','medium-m']"></fa-icon>
            <span>Medium</span>
          </a>
          <a href="https://www.twitter.com/tomastrajan" target="_blank">
            <fa-icon [icon]="['fab','twitter']"></fa-icon>
            <span>Twitter</span>
          </a>
          <a href="https://www.youtube.com/channel/UC7XgRHIVoqnh3U5Vmly9ofQ"
            target="_blank">
            <fa-icon [icon]="['fab','youtube']"></fa-icon>
            <span>Youtube</span>
          </a>
          <a href="https://www.instagram.com/tomastrajan" target="_blank">
            <fa-icon [icon]="['fab','instagram']"></fa-icon>
            <span>Instagram</span>
          </a>
          <a href="https://www.slides.com/tomastrajan" target="_blank">
            <fa-icon icon="play-circle"></fa-icon>
            <span>Slides</span>
          </a>
          -->
        <!--/div>
      </div-->
      <div class="row">
        <div class="col-12 signature">
          &#169; <span class="year">{{year}}</span> - Jordi Marti
          <br class="d-block d-sm-none">
          <a [matTooltip]="'anms.footer.changelog' | translate"
            matTooltipPosition="before"
            href="https://github.com/tomastrajan/angular-ngrx-material-starter/blob/master/CHANGELOG.md">
            <fa-icon icon="rocket"></fa-icon>
            {{version}} <span *ngIf="!isProd">[{{envName}}]</span>
          </a>
        </div>
      </div>
  </div>
  <!--anms-bottom-menu *ngIf="isAuthenticated$ | async as autenticated"></anms-bottom-menu-->
 </main>
</div>

